.tracked-courses-view {
  text-align: left;
  margin: 60px 0;
}

.no-tracked-course {
  text-align: center;
}

.tracking-title-label {
  font-weight: 500;
  font-size: 20px;
}

.tracking-subtitle-label {
  font-weight: 500;
  font-size: 16px;
  color: #9EA7B3;
}

.num-available-label {
  width: 80%;
  color: #47C753;
  font-weight: 600;
  font-size: 24px;
  padding-left: 30px;
  margin: 10px auto;
}

.num-awaiting-label {
  width: 80%;
  color: #050505;
  font-weight: 600;
  font-size: 24px;
  padding-left: 30px;
  margin: 30px auto 10px auto;
}

.all-tracked-courses {
  margin: auto;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
