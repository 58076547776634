.tracked-course-card {
  background: #FFFFFF;
  border: 1px solid #D1D5DA;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 15px 20px 15px;
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title-label {
  color: #000000;
  font-weight: 600;
  font-size: 24px;
  padding: 4px 12px;
  margin: 20px 7px;
}

.section-label {
  color: #6A737D;
  font-weight: 600;
  font-size: 16px;
  padding: 4px 12px;
  margin: 4px 7px;
}

.catalog-num-label {
  color: #24292E;
  font-weight: 500;
  font-size: 14px;
  background: #E1E4E8;
  border: 1px solid #E1E4E8;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 4px 12px;
  margin: 4px 7px;
}

.mode-label {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
  background: #6A737D;
  border: 1px solid #6A737D;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 4px 12px;
  margin: 4px 7px;
}

.status-icon {
  margin: 32px 20px 20px 10px;
}

.course-info-view {
  display: flex;
  flex-direction: row;
}

.title-status-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
  margin: 15px 13px 21px 13px;
}

button:focus {
  outline: 0;
}

button:hover {
  cursor: pointer;
}

.tracked-card-remove-button {
  font-weight: 500;
  font-size: 12px;
  color: #CA4238;
  background-color: #FFFFFF;
  border: 1px solid #CA4238;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 12px;
  margin: 0px 6px;
  flex-grow: 1;
}

.tracked-card-enroll-button {
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #050505;
  border: 1px solid #050505;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 12px;
  margin: 0px 6px;
  flex-grow: 1;
}
