.search-container {
  margin-left: 15px;
}

.search-bar {
  width: 40vw;
  height: 35px;
  margin: 0;
  background: #FFFFFF;
  border: 1px solid #EBECEE;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  border: 0;
  flex: 1;
  font-weight: 600;
  font-size: 16px;
  margin-right: 15px;
}

.search-input:focus {
  outline-width: 0;
}

.search-results-dropdown {
  width: 40vw;
  text-align: left;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #EBECEE;
  border-radius: 0px 0px 15px 15px;
  z-index: 99;
}

.search-result {
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 600;
  font-size: 16px;
}

.search-icon {
  padding: 12px 16px;
}

.result-text {
  margin: 0;
  color: black !important;
}
