.nav-view {
  text-align: center;
  top: 0;
  position: fixed;
  background-color: black;
  width: 100%;
  display: inline-block;
  padding: 15px 0 8px 0;
}

.nav-view a {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.nav-view a:hover {
  opacity: 0.8;
}

.nav-left {
  height: 35px;
  float: left;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.coursegrab-text {
  padding: 5px 15px;
}

.nav-right {
  height: 35px;
  float: right;
  font-size: 18px;
  padding-top: 5px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.sign-in-out-button {
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  background-color: #050505;
  border: none;
}